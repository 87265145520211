import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPreviewService } from '../../../service/media-preview.service';

export enum ProfileAvatarSize {
  profile = 'profile',
  comment = 'comment',
  chat = 'chat',
  notification = 'notification',
  navigation = 'navigation',
  new_profile = 'new-profile',
  post = 'post',
}

@Component({
  selector: 'app-profile-avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-avatar.component.html',
  styleUrl: './profile-avatar.component.scss',
})
export class ProfileAvatarComponent {
  readonly profileAvatarSize = ProfileAvatarSize;
  previewService = inject(MediaPreviewService);
  @Input({ required: true }) url: string | null = null;
  @Input() size: string = ProfileAvatarSize.profile;
  @Input() isCircle: boolean = false;
  @Input() online: boolean | null = null;
  @Input() preview: boolean = true;
  @Input() isCreator: boolean = true;

  get sizePx() {
    switch (this.size) {
      case ProfileAvatarSize.chat:
        return 48;
      case ProfileAvatarSize.profile:
        return 80;
      case ProfileAvatarSize.comment:
      case ProfileAvatarSize.notification:
        return 40;
      case ProfileAvatarSize.navigation:
        return 32;
      case ProfileAvatarSize.post:
        return 64;
    }
    return 80;
  }

  showImage() {
    if (
      this.size !== ProfileAvatarSize.chat &&
      this.size !== ProfileAvatarSize.notification &&
      this.preview
    ) {
      this.previewService.showImageFileUrl(this.url);
    }
  }
}
