<div class="content"
    [ngClass]="[
        isCircle ? 'circle' : '',
        online ? 'online' : '',
        isCreator ? 'creator' : '',
        'marker-size-' + size
      ]">
  <div class="profile-avatar" [ngClass]="['size-' + size ]">

    <ng-container *ngTemplateOutlet="!!this.url ? fromUrl : placeholder"></ng-container>
  <!-- <ng-container *ngIf="size === profileAvatarSize.new_profile">
    <ng-container *ngTemplateOutlet="statusLabel"></ng-container>
  </ng-container> -->
  </div>
  <ng-template #fromUrl>
    <div class="profile-avatar-img" (click)="showImage()">
      <img [src]="url" alt="profile-avatar" srcset="" class="cover-fit" [width]="sizePx" [height]="sizePx">
    </div>
  </ng-template>
  <ng-template #placeholder>
    <div class="profile-avatar-img profile-avatar-placeholder"></div>
  </ng-template>
  <ng-template #statusLabel>
    <div class="online-label"></div>
  </ng-template>
</div>
